.navbarBody {
  transition: 0.2s ease;
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 0 1px 1px rgba(29, 17, 51, .04), 0 0 3px 2px rgba(9, 32, 77, .12), 0 0 2px -3px rgba(29, 17, 51, .12);
}

.navbar-nav {
  padding: 0;
  margin: 0;
}

.navSize {
  width: 100% !important;
  height: 68px;
  transition: 0.3s ease;
  box-shadow: none;
  background-color: #fff;
}
.navSize img{
  max-height: 57px;
  transition: .37s;
}

.navSizeSM {
  transition: .37s;
  height: 56px;
}
.navSizeSM img{
  max-height: 50px;
}
.navSizeSM button.btn-primary {
  transform: scale(1);
  transition: 0.3s;
}

navSizeSM button.btn-primary:hover {
  transform: scale(0.9);
  transition: 0.3s;
}

.navSize a.nav-link {
  padding: 10px 11px !important;
  height: 78px;
  display: flex;
  align-items: center;
}

.BrandLogo {
  width: 194px;
  height: 52px;
  transition: 0.3s ease-out;
}

.BrandLogoSM {
  transition: 0.3s ease-out;
  width: 149px !important;
  height: 40px !important;
}

.activeNav {
  position: relative;
  display: inline-block;
}

.dropdown-toggle::after {
  content: none !important;
}

.activeNav a.show {
  /* color: white !important; */
}

@media screen and (min-width: 992px) {

  .activeNav::after {
    content: ' ';
    position: absolute;
    left: 10%;
    top: 75%;
    width: 80%;
    height: 3px;
    border-radius: 2px;
    /* Adjust thickness as needed */
    background-color: blue;
    /* Adjust color as needed */
    /* transform: scaleX(0); */
  }
}

@media only screen and (max-width: 992px) {

  .activeNav::after {
    /* animation: slideAnim 1s ease-out forwards; */
    scale: 1;
  }
}

@media only screen and (max-width: 991px) {
  .navSize {
    height: auto;
  }

  .navSize button.btn-primary {
    margin-bottom: 1rem !important;
  }
}

/* 
@keyframes slideAnim {
  from {
    transform: scaleX(0);
    opacity: 10%;
  }

  to {
    transform: scaleX(1);
    opacity: 100%;
  }
} */


.dropdown-menu {
  
  margin-top: 0rem !important;
  min-width: 250px !important;
  overflow: hidden;
  animation: appendAnim 0.34s forwards;
}

.navLink {
  margin: 0.2rem 0 !important;
  height: 3rem !important;
  z-index: 99;
  padding: 5px 10px;
}

.navLink:hover {
  font-weight: bold;
  background-color: rgb(249, 249, 250);
}

.navLink svg,
.navLink img {
  stroke: black;
  width: 0;
  transform: scaleX(0);
  padding-left: 5px;
  transition: 0.3s ease;
}

.navLink:hover svg {
  width: 24px;
  transition: 0.3s ease;
  transform: scaleX(1);
}

.navLink:hover img {
  width: 21px;
  transition: 0.3s ease;
  transform: scaleX(1);
}

.current-page {
  position: relative;
}

.current-page::after {
  content: " ";
  position: absolute;
  width: 70%;
  left: 15%;
  bottom: 12%;
  height: 2px;
  border-radius: 2px;
  background-color: #1030D0;
  opacity: .9;
}

@media only screen and (max-width: 992px) {
  .show{
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .navLink {
    height: 2.5rem !important;
    z-index: 99;
    background-color: #fff;
    margin: 0 !important;
    padding-left: 10px !important;
  }
  .dropdown-menu{
    padding-left: 10px !important;
    border: none !important;
    height: min-content !important;
    animation: appendAnim 0.34s forwards;
  }
  
  .current-page {
    width: fit-content;
  }

  .navbar-nav {
    background-color: #fff;
    padding-left: 10px !important;
    padding-bottom: 10px !important;
  }
}

@keyframes appendAnim {
  from {
    height: 0rem;
  }

  to {
    height: 7.5rem;
  }
}

/* 
wiem, że wygląda to źle, ale nadpisywanie styli react-bootstrapa tak wyglądać musi :/ 
*/

.accordion-button{
  padding-left: 12px !important;
  color: rgba(0, 0, 0, .6) !important;
  background-color: #fff !important;
}
.accordion-button::after{
  display: none;
}
.accordion-item{
  width: 160px;
  border: none !important;
}
.accordion-item:active{
  border: none !important;
}
.accordion-button:not(.collapsed){
  color: black !important;
  box-shadow: none !important;
  border-bottom: 3px solid #1030D0 !important;
}
.collapsed:focus{
  color: rgba(0, 0, 0, .6) !important;
  border: none !important;
  box-shadow: none !important;
}
.collapsed:hover{
  color: black !important;
}

.staticNav{
  padding-bottom: 0px !important;
}


.udnerlinehover{
  position: relative;
  width: min-content;
}

.udnerlinehover:after{
  position: absolute;
  width: 0%;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  content: "";
  background-color: #302b63;
  border-radius: 2px;
  bottom: 25%;
  transition: .37s;
}
.udnerlinehover:hover::after{
  width: 86%;
  transition: .37s;
}

@media screen and (max-width : 768px) {
  .udnerlinehover::after{
    transform: translateX(0%);
    left: 7%;
  }
}