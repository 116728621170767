h1{
    font-size: 5vw;
}
.bg-req{
    color: rgb(221, 255, 0);
}
.info{
    position: absolute;
    width: 70%;
    border-radius: 15px;
    content: "";
    background-color: green;
    height: fit-content;
    padding-bottom: 70px;
    text-align: center;
    padding-top: 40px;
    visibility: hidden;
}
.info-parent{
    position: relative;
}
.form-sended{
    opacity: 0;
    display: none;
}
.error{
    position: absolute;
    width: 70%;
    height: fit-content;
    border-radius: 15px;
    content: "";
    background-color: rgb(112, 0, 0);
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
    visibility: hidden;
}
a{
    cursor: pointer !important;
}
