.footer-img a img{
    max-height: 200px;
    width: 90%;
    object-fit: contain;
    object-position: center center;
    transition: .37s;
}
.footer-img a img:hover{
    transition: .37s;
    scale: 1.05;
}
.footer-img a {
    width: 90%;
    padding: none;
    margin: none;
}
.main{
    background-color: #0f0c29;
}
a{
    color: white;
    font-size: 1.3rem;
}
.hr{
    margin-top: 10px;
    width: 66%;
    height: 1vw;
    max-height: 4px;
    background-color: #fff;
    border-radius: 3px;
}