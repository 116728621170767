.side-label{
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    max-width: 40vw;
    margin-bottom: 20px;
    padding: 2vw;
    border-bottom-right-radius: 200px; 
    border-top-right-radius: 200px;

}
.side-label img{
    width: 75%;
}
.header-con{
    height: min-content;
}

.header1{
    width: 65%;
}
.header-image{
    width: 35%;
    height: 100%;
}
.header-image img{
    width: 50%;
}
h1 span{
    text-transform: uppercase;   
    font-weight: bolder;
}

h1 span.top-text{
    font-size: 11vw;
}

h1 span.bottom-text{
    font-size: 5.5vw;
    color: rgb(67, 67, 255);
}
.underline{
    padding-left: 1px;
}
.underline div{
    height: 1vw;
    max-height: 6px;
    background-color: #fff;
    border-radius: 3px;
}


.basic-info span{
    font-size: 2rem;
}


.vl{
    visibility: visible;
    width: 4px;
    border-radius: 3px;
    background-color: #fff;
}

@media screen and (max-width : 760px) {
    .vl{
        visibility: hidden;
    }
    .basic-info div:nth-child(2){
        order: 3;
        margin-top: 12vh !important;
    }
    .basic-info div:nth-child(3){
        order: 2;
    }
}

.basic-info div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media screen and (min-width : 2100px){
    
h1 span.top-text{
    font-size: 15rem;
}

h1 span.bottom-text{
    font-size: 7.5rem;
    color: rgb(67, 67, 255);
}
}
.text-con{
    background-color: #0f0c29;
    border-radius: 25px;
    margin-bottom: 20vh;
}