body {

  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
}

body{
  background-image: url('./images/tlo.png');
  background-repeat: no-repeat;
  background-attachment:fixed;
  background-position: center center;
  background-size: contain;
  
  background-color: #0f0c29;  /* fallback for old browsers */
  background-color: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
  background-color: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}
.bg-lighter{
  background-color: #181532;
}